import React from "react"

const SquareSlider = ({ id, label, checked, handleChange }) => {
  return (
    <div className="d-flex gap-2 align-items-center">
      <label htmlFor={id} className="">
        {label}
      </label>
      <div className="square-switch d-flex align-items-center">
        <input
          type="checkbox"
          id={id}
          switch="info"
          defaultChecked={checked}
          onChange={handleChange}
        />
        <label htmlFor={id} data-on-label="Yes" data-off-label="No" />
      </div>
    </div>
  )
}

export default SquareSlider
