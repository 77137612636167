import { stakeholderPaths } from "common/data/routePaths"
import SpecialNotesModal from "components/TP/Common/Modals/SpecialNotesModal"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"
import React, { Fragment, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Badge, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"
import { beneficaryBenefitTOBId } from "utils/modulesIds"
import MedicalFilesTableModal from "./MedicalFilesTableModal"
import { buttonsTitles, modalsStates } from "common/data"
import NetworkContractsModal from "./NetworkContractsModal"
import ChatModal from "components/TP/Chat/ChatModal"
import WhatssAppModal from "./WhatssAppModal"
import FocSpentModal from "../Beneficiary/FocSpentModal"
import FOBSpentModal from "../Beneficiary/FOBSpentModal"
import SendEmailModal from "./SendEmailModal"
import { uniqBy } from "lodash"
import SourceTaskDropdown from "components/TP/Common/General/SourceTaskDropdown/SourceTaskDropdown"
import ClaimMOCasesModal from "./ClaimMOCasesModal"
import ClaimChatNotification from "../Chat/ClaimChatNotification"

const ClaimsBreadcrumbAddons = ({
  beneficiary,
  isLoading,
  claim,
  networkId,
}) => {
  const { claimId } = useParams()

  const [network, setNetwork] = useState(null)
  // store modal states for all modals
  // in this file
  const [isModal, setIsModal] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { isVIP } = useMemo(() => {
    return {
      isVIP:
        beneficiary?.client_class?.slug === "memberClass_vip" ? true : false,
    }
  }, [JSON.stringify(beneficiary)])

  if (isLoading)
    return (
      <div className="bg-white text-danger rounded-1 p-2">
        <MultiSkeleton length={1} />
      </div>
    )

  return (
    !isLoading &&
    beneficiary && (
      <Fragment>
        <div className="d-flex align-items-center">
          {beneficiary?.status?.id === 115 ? (
            <Badge
              color="purple"
              className="font-size-14 align-self-start ms-2"
            >
              Blacklisted
            </Badge>
          ) : null}
          {isVIP ? (
            <Badge
              color="primary"
              className="font-size-16 align-self-start ms-2"
              id="neon-badge"
            >
              {beneficiary?.client_class?.name}
            </Badge>
          ) : null}
          <ul className="list-unstyled hstack gap-1  ms-2 flex-wrap">
            <li>
              <Link
                className="badge bg-primary fw-semibold py-2 px-2"
                to={`${stakeholderPaths.beneficiaryList}/view/${beneficiary?.id}`}
                target="_blank"
              >
                {buttonsTitles.viewProfile}
              </Link>
            </li>
            <li>
              <button
                aria-label="Beneficiary Medical File"
                className="border-0 badge bg-primary fw-semibold py-2 px-2"
                onClick={() => setIsModal(modalsStates.medicalFilesModal)}
              >
                {buttonsTitles.medicalFile}
              </button>
            </li>
            <li>
              <button
                aria-label="Beneficiary FOC Spent"
                className="border-0 badge bg-primary fw-semibold py-2 px-2"
                onClick={() => setIsModal(modalsStates.focSpent)}
              >
                {buttonsTitles.focSpent}
              </button>
            </li>
            <li>
              <button
                aria-label="Beneficiary FOC Spent"
                className="border-0 badge bg-primary fw-semibold py-2 px-2"
                onClick={() => setIsModal(modalsStates.fobSpent)}
              >
                {buttonsTitles.fobSpent}
              </button>
            </li>
            <li>
              <Link
                className="badge bg-primary fw-semibold py-2 px-2"
                target="_blank"
                to={{
                  pathname: `${stakeholderPaths.beneficiaryList}/view/${beneficiary?.id}#${beneficaryBenefitTOBId}`,
                  state: { id: beneficaryBenefitTOBId },
                }}
              >
                {buttonsTitles.tableofBenefits}
              </Link>
            </li>
            <li>
              <button
                className="badge border-0 bg-primary fw-bold py-2 px-2"
                onClick={() => setIsModal(modalsStates.sepcialNotes)}
              >
                {buttonsTitles.viewNotes}
              </button>
            </li>

            {/* networks */}
            {beneficiary?.policy?.product?.product_networks?.length > 0 &&
              uniqBy(beneficiary?.policy?.product?.product_networks, "id")?.map(
                (productNetwork, index) => {
                  const isNetworkFoundInPlans =
                    beneficiary?.co_participations?.length > 0 &&
                    beneficiary?.co_participations?.find(
                      plan =>
                        plan?.benefit?.id === productNetwork?.benefit?.id &&
                        plan?.class?.id === productNetwork?.class?.id
                    )
                      ? true
                      : false
                  return isNetworkFoundInPlans ? (
                    <li
                      className="badge bg-primary fw-semibold py-2 px-2"
                      key={`${productNetwork.id}-${productNetwork?.benefit?.id}-${index}`}
                      onClick={() => {
                        setIsModal(modalsStates.networkModal)
                        setNetwork({
                          id: productNetwork.id,
                          name: productNetwork?.network?.name,
                        })
                      }}
                    >
                      {productNetwork?.network?.name?.substring(0, 9) + "..."}
                    </li>
                  ) : null
                }
              )}

            {/* Medical Opinion Cases for this Claim */}
            {claimId && (
              <li>
                <ClaimMOCasesModal claim={claim} />
              </li>
            )}

            {claimId ? (
              <li className="position-relative">
                <button
                  className="badge border-0 bg-primary fw-bold py-2 px-2"
                  onClick={() => setIsModal(modalsStates.chatModal)}
                >
                  {buttonsTitles.chat}
                  <ClaimChatNotification claim={claim} inClaimForm={true} />
                </button>
              </li>
            ) : null}
            {/* send wattsapp and Email buttons */}
            {claimId ? (
              <li>
                <Dropdown
                  isOpen={isMenuOpen}
                  toggle={() => setIsMenuOpen(!isMenuOpen)}
                  className="align-self-center"
                >
                  <DropdownToggle
                    className="badge border-0 bg-primary fw-bold py-2 px-2 d-flex gap-2"
                    caret
                  >
                    <span>{buttonsTitles.send}</span>
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <button
                      className="dropdown-item d-flex align-content-center gap-3"
                      onClick={() => {
                        setIsModal(modalsStates.whatAppModal)
                        setIsMenuOpen(false)
                      }}
                    >
                      <i className="bx bxl-whatsapp font-size-17" />
                      <span>{buttonsTitles.sendWhatsapp}</span>
                    </button>
                    <button
                      className="dropdown-item d-flex align-content-center gap-3"
                      onClick={() => {
                        setIsModal(modalsStates.emailModal)
                        setIsMenuOpen(false)
                      }}
                    >
                      <i className="bx bx-envelope font-size-16" />
                      <span>{buttonsTitles.sendEmail}</span>
                    </button>
                  </DropdownMenu>
                </Dropdown>
              </li>
            ) : null}

            {claimId && (
              <li>
                <SourceTaskDropdown sourceId={claimId} sourceType="Claim" />
              </li>
            )}
          </ul>
        </div>

        {isModal === modalsStates.sepcialNotes && (
          <SpecialNotesModal
            show={isModal === modalsStates.sepcialNotes}
            onCloseClick={() => setIsModal(false)}
            beneficaryId={beneficiary?.id}
            beneficary={beneficiary}
          />
        )}
        {isModal === modalsStates.chatModal && (
          <ChatModal
            show={isModal === modalsStates.chatModal}
            onCloseClick={() => setIsModal(false)}
            resourceId={claimId}
          />
        )}

        {isModal === modalsStates.medicalFilesModal && (
          <MedicalFilesTableModal
            show={isModal === modalsStates.medicalFilesModal}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
            beneficiary={beneficiary}
          />
        )}

        {isModal === modalsStates.focSpent && (
          <FocSpentModal
            show={isModal === modalsStates.focSpent}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
          />
        )}
        {isModal === modalsStates.fobSpent && (
          <FOBSpentModal
            show={isModal === modalsStates.fobSpent}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
            beneficiary={beneficiary}
          />
        )}

        {isModal === modalsStates.networkModal && (
          <NetworkContractsModal
            show={isModal === modalsStates.networkModal}
            onCloseClick={() => setIsModal(false)}
            networkId={network?.id}
            networkName={network?.name}
          />
        )}
        {isModal === modalsStates.whatAppModal && (
          <WhatssAppModal
            isModal={isModal === modalsStates.whatAppModal}
            setIsModal={setIsModal}
            claim={claim}
          />
        )}
        {isModal === modalsStates.emailModal && (
          <SendEmailModal
            isModal={isModal === modalsStates.emailModal}
            setIsModal={setIsModal}
            claim={claim}
          />
        )}
      </Fragment>
    )
  )
}

export default ClaimsBreadcrumbAddons
