// paths
import { networkPaths } from "common/data/routePaths"

import { lazy } from "react"

// Components

const HealthCareContract = lazy(() =>
  import("pages/Networks/HealthCareContract")
)
const HealthCareContractTabs = lazy(() =>
  import("pages/Networks/HealthCareContract/HealthCareContractTabs")
)
const NetworkList = lazy(() => import("pages/Networks/NetworkList"))
// featured Items
const FeaturedItems = lazy(() => import("pages/Networks/FeaturedItems"))

const NetworkForms = lazy(() =>
  import("pages/Networks/NetworkList/NetworkForms")
)
const UploadContractPrices = lazy(() =>
  import(
    "pages/Networks/HealthCareContract/ServicesAndTarrifTab/UploadContractPrices"
  )
)

const { networks, healthcare_contracts, featuredItems } = networkPaths

export const Networks = [
  { path: healthcare_contracts, component: HealthCareContract },
  { path: `${healthcare_contracts}/add`, component: HealthCareContractTabs },
  {
    path: `${healthcare_contracts}/edit/:id`,
    component: HealthCareContractTabs,
  },
  {
    path: `${healthcare_contracts}/view/:id`,
    component: HealthCareContractTabs,
  },
  {
    path: `${healthcare_contracts}/:id/import`,
    component: UploadContractPrices,
  },
  { path: networks, component: NetworkList },
  { path: `${networks}/add`, component: NetworkForms },
  { path: `${networks}/edit/:id`, component: NetworkForms },
  { path: `${networks}/view/:id`, component: NetworkForms },

  { path: featuredItems, component: FeaturedItems },
]
