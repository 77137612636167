import React, { lazy } from 'react';
// paths
import { settingsPaths } from 'common/data/routePaths';

// Regions
const Regions = lazy(() => import('pages/Settings/Regions'));
// Predefined
const Predefined = lazy(() => import('pages/Settings/Predefined'));
// Job Titles
const JobTitles = lazy(() => import('pages/Settings/JobTitles'));
// Job Roles
const JobRoles = lazy(() => import('pages/Settings/JobRoles'));
const JobRoleForm = lazy(() => import('pages/Settings/JobRoles/jobRoleForm'));
// Priority Payer
const PriorityPayers = lazy(() => import('pages/Settings/PriorityPayers'));
// Users

const Users = lazy(() => import('pages/Settings/Users'));
const UsersForm = lazy(() => import('pages/Settings/Users/usersForm'));
// Companies
const Company = lazy(() => import('pages/Settings/Company'));
// Taxes
const Taxes = lazy(() => import('pages/Settings/Taxes'));
const Logs = lazy(() => import('pages/Settings/Logs'));
const FieldsControl = lazy(() => import('pages/Settings/FieldsControl'));
const UsersActivity = lazy(() => import('pages/Settings/UsersActivity'));
// Workflow

const Workflow = lazy(() => import('pages/Settings/Workflow'));
const AddWorkflow = lazy(() => import('pages/Settings/Workflow/AddWorkflow'));

// Notifications Template
const NotificationsTemplate = lazy(() =>
  import('pages/Settings/NotificationsTemplate')
);
const NotificationsTemplateTabs = lazy(() =>
  import('pages/Settings/NotificationsTemplate/NotificationsTemplateTabs')
);

/* claim Rule */

const ClaimRules = lazy(() => import('pages/Settings/ClaimRules'));
const ClaimRulesForms = lazy(() =>
  import('pages/Settings/ClaimRules/ClaimRulesForms')
);
const ClaimAuthority = lazy(() =>
  import('pages/Settings/ClaimFinancailAuthority')
);
const ClaimDistributions = lazy(() =>
  import('pages/Settings/ClaimDistributions')
);

// Billing Panels
const BillingPanels = lazy(() => import('pages/Settings/BillingPanels'));
const BillingPanelsForms = lazy(() =>
  import('pages/Settings/BillingPanels/BillingsPanelForms')
);

//informative pages
const InformativePages = lazy(() =>
  import('pages/Settings/InformativePages/Index')
);
const InformativePagesForms = lazy(() =>
  import('pages/Settings/InformativePages/InformativePagesForms')
);

// desctructure the paths
const {
  claimRule,
  notificationTemplate,
  claimAuthority,
  claimDistribution,
  billingPanels,
  informativePages,
} = settingsPaths;

export const settingsRoutes = [
  // Regions
  { path: '/regions', component: Regions },

  // Predefined Values
  { path: '/predefined-values', component: Predefined },

  // Taxes
  { path: '/taxes', component: Taxes },

  // Job Titles
  { path: '/job-titles', component: JobTitles },

  // Job Roles
  { path: '/job-roles', component: JobRoles },
  { path: '/job-roles/add', component: JobRoleForm },
  { path: '/job-roles/edit/:id', component: JobRoleForm },
  { path: '/job-roles/view/:id', component: JobRoleForm },

  // Priority Payers
  // { path: "/priority-payers", component: PriorityPayers },
  { path: '/priority-payer', component: PriorityPayers },

  // users
  { path: '/users', component: Users },
  // Add New User
  { path: '/users/add', component: UsersForm },
  { path: '/users/edit/:id', component: UsersForm },

  // add page , there is no list page so far
  // companies
  { path: '/company', component: Company },
  // logs
  { path: '/logs/:moduleId/:resourceId', component: Logs },
  // user activites
  { path: '/users/:resourceId/activity', component: UsersActivity },
  //  fieldsControl
  { path: '/fields-control', component: FieldsControl },
  // workflow
  { path: '/workflows', component: Workflow },
  // Add workflow
  { path: '/workflows/add', component: AddWorkflow },
  // Edit workflow
  { path: '/workflows/edit/:id', component: AddWorkflow },
  // view workflow
  { path: '/workflows/view/:id', component: AddWorkflow },

  // Notifications Template
  { path: notificationTemplate, component: NotificationsTemplate },
  // Add Notifications Template
  { path: `${notificationTemplate}/add`, component: NotificationsTemplateTabs },
  //Edit Notifications Template
  {
    path: `${notificationTemplate}/edit/:id`,
    component: NotificationsTemplateTabs,
  },

  /* Claim Rules */

  { path: `${claimRule}`, component: ClaimRules },
  { path: `${claimRule}/add`, component: ClaimRulesForms },
  { path: `${claimRule}/edit/:claimRuleId`, component: ClaimRulesForms },
  { path: `${claimRule}/view/:claimRuleId`, component: ClaimRulesForms },

  // claim authority
  { path: `${claimAuthority}`, component: ClaimAuthority },

  // claim distribution
  { path: `${claimDistribution}`, component: ClaimDistributions },
  // claim Panels
  { path: billingPanels, component: BillingPanels },
  { path: `${billingPanels}/add`, component: BillingPanelsForms },
  { path: `${billingPanels}/edit/:id`, component: BillingPanelsForms },
  { path: `${billingPanels}/view/:id`, component: BillingPanelsForms },

  /* informative pages */

  { path: `${informativePages}`, component: InformativePages },
  { path: `${informativePages}/add`, component: InformativePagesForms },
  {
    path: `${informativePages}/edit/:informativePagesId`,
    component: InformativePagesForms,
  },
  {
    path: `${informativePages}/view/:informativePagesId`,
    component: InformativePagesForms,
  },
];
