// paths

import { productionPaths } from "common/data/routePaths"
import UploadTOB from "pages/Production/Products/TableOfBenefitsTab/UploadTOB"
import { lazy } from "react"
// Components
const PayerAgreement = lazy(() => import("pages/Production/PayerAgreement"))
const PayerAgreementTabs = lazy(() =>
  import("pages/Production/PayerAgreement/PayerAgreementTabs")
)
const Products = lazy(() => import("pages/Production/Products"))
const ProductTabs = lazy(() => import("pages/Production/Products/ProductTabs"))

const Policies = lazy(() => import("pages/Production/Policies"))
const PolicyTabs = lazy(() => import("pages/Production/Policies/PolicyTabs"))

const PolicyEndorsement = lazy(() =>
  import("pages/Production/PolicyEndorsement")
)
const UploadBeneficiaries = lazy(() =>
  import("pages/Production/Policies/UploadBeneficiaries")
)

// Upload Policies
const UploadPolicies = lazy(() =>
  import("pages/Production/Policies/UploadPolicies")
)
const UploadPICs = lazy(() =>
  import("pages/Production/Products/PCITab/UploadPICs")
)
const EndoresmentPrint = lazy(() =>
  import("pages/Production/PolicyEndorsement/EndoresmentPrint/EndoresmentPrint")
)
// View Policy Endorsment by ref
const ViewPolicyEndorsementRef = lazy(() =>
  import(
    "pages/Production/Policies/PolicyEndorsementTab/ViewPolicyEndorsementRef"
  )
)
const ProductPdf = lazy(() =>
  import("pages/Production/Products/PDF/ProductPdf")
)

const TreatyProgram = lazy(() => import("pages/Production/TreatyProgram"))
const UploadSpecialBeneficiaries = lazy(() =>
  import("pages/Production/Policies/UploadSpecialBeneficiaries")
)
const BeneficiariesPremiums = lazy(() =>
  import(
    "pages/Production/Policies/SecondTab/PrintPremiums/BeneficiariesPremiums"
  )
)

// destructure production paths
const { products, payer_agreements, policy } = productionPaths

export const Productions = [
  { path: payer_agreements, component: PayerAgreement },
  { path: `${payer_agreements}/add`, component: PayerAgreementTabs },
  { path: `${payer_agreements}/edit/:id`, component: PayerAgreementTabs },
  { path: `${payer_agreements}/view/:id`, component: PayerAgreementTabs },
  // products
  { path: products, component: Products },
  { path: `${products}/add`, component: ProductTabs },
  { path: `${products}/edit/:id`, component: ProductTabs },
  { path: `${products}/view/:id`, component: ProductTabs },
  { path: `${products}/print/:id`, component: ProductPdf },
  { path: `${products}/:id/import`, component: UploadTOB },

  // batch upload PCIS
  { path: `${products}/pci/:id/import`, component: UploadPICs },
  // Policies
  { path: policy, component: Policies },
  { path: `${policy}/add`, component: PolicyTabs },
  { path: `${policy}/edit/:id`, component: PolicyTabs },
  { path: `${policy}/view/:id`, component: PolicyTabs },
  // batch Upload Beneficiary
  { path: "/beneficiaries/:policyId/import", component: UploadBeneficiaries },
  { path: `${policy}/import`, component: UploadPolicies },

  // special Upload Beneficiary
  {
    path: `${policy}/:policyId/beneficiary-import-nextcare`,
    component: UploadSpecialBeneficiaries,
  },
  //print premiums for all policy members
  {
    path: `${policy}/:policyId/:beneficiaryIDs/premiums`,
    component: BeneficiariesPremiums,
  },
  //  view policy endorsment by ref
  {
    path: "/policy-endorsement/viewRef/:reference",
    component: ViewPolicyEndorsementRef,
  },
  // policy endorsement
  // with policy ID (if beneficiary has policy Id)
  { path: "/policy-endorsement/:id/:policyId", component: PolicyEndorsement },
  // without policy ID (if beneficiary has no policy Id)
  { path: "/policy-endorsement/:id", component: PolicyEndorsement },
  {
    path: "/policy-endorsement/print/:id/:policyId",
    component: EndoresmentPrint,
  },

  {
    path: "/treaty",
    component: TreatyProgram,
  },
]
