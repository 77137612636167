import React, { Fragment, useState } from "react"
import { Input, InputGroup, Label, UncontrolledTooltip } from "reactstrap"
import FormInputError from "./FormInputError"
import { useLocation } from "react-router-dom"
import { NumericFormat } from "react-number-format"
import { isFloat } from "utils/smallUtils"
import { usePageType } from "hooks"
import MultiSkeleton from "../MultiSkeleton"

const FormControll = ({
  id,
  name,
  type,
  value,
  min,
  max,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  error,
  wrapperClassNames,
  children,
  onChange,
  validation,
  includeNumricFormat,
  readOnly,
  horizontalLayout,
  noLabel,
  labelPrefix,
  labelSuffix,
  labelAddOns,
  customOnChange,
  isLoading,
  allowNegative,
  disableViewPage = false,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const label =
    inputField && inputField.label_name ? inputField.label_name : propLabel
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`

  const { invalid } = rest

  const { isViewPage: isInViewPage, isViewProcessingPage } = usePageType()
  const isViewPage = isInViewPage && !disableViewPage
  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {!noLabel && (
        <div className="d-flex align-items-start gap-2">
          {/* tooltip for label from placeholder if */}
          <Fragment>
            <Label
              htmlFor={id}
              className={`text-capitalize ${
                inputField?.is_required ? "required-label" : ""
              } ${labelClass ? labelClass : ""}`}
              // data-toggle="tooltip"
              // title={placeholder ? placeholder : ""}
              id={`tooltip-${id}`}
            >
              {labelPrefix && labelPrefix}
              {label}
              {labelSuffix && ` ${labelSuffix}`}
            </Label>
            {placeholder && !readOnly && (
              <UncontrolledTooltip placement="top" target={`tooltip-${id}`}>
                {placeholder}
              </UncontrolledTooltip>
            )}
          </Fragment>

          {labelAddOns && labelAddOns}
        </div>
      )}
      <div
        className={horizontalLayout && inputClass}
        // style={{ height: type !== "textarea" && "40px" }}
      >
        {includeNumricFormat ? (
          <NumericFormat
            id={id}
            name={name}
            min={min ? min : undefined}
            max={max ? max : undefined}
            value={value && value}
            placeholder={readOnly ? "" : placeholder}
            thousandSeparator=","
            valueIsNumericString={true}
            allowNegative={allowNegative ? allowNegative : false}
            {...rest}
            onValueChange={values => {
              const { value, floatValue } = values
              // validation.setFieldValue(`${name}`, value)
              // onChange(`${name}`, value)
              if (customOnChange) {
                customOnChange(floatValue)
                return
              }
              if (onChange) {
                onChange(`${name}`, floatValue)
              }
            }}
            invalid={
              isViewPage || isViewProcessingPage || readOnly ? false : invalid
            }
            customInput={Input}
            // for decimal sperator
            decimalScale={2}
            fixedDecimalScale={value && isFloat(value) ? true : false}
            // for readonly component
            isAllowed={() =>
              isViewPage || isViewProcessingPage || readOnly ? false : true
            }
            displayType={
              isViewPage || isViewProcessingPage || readOnly ? "text" : "input"
            }
            renderText={value => (
              <Input
                value={value}
                onChange={undefined}
                className="bg-gray-600 bg-opacity-10"
                readOnly={true}
              />
            )}
          />
        ) : type === "password" ? (
          <InputGroup>
            <Input
              id={id}
              name={name}
              type={showPassword ? "text" : "password"}
              value={value && value}
              placeholder={readOnly ? "" : placeholder}
              className={`${inputClass ? inputClass : ""} ${
                isViewPage || isViewProcessingPage ? "border-0" : ""
              } ${readOnly ? "bg-gray-600 bg-opacity-10" : ""}`}
              {...rest}
              invalid={
                isViewPage || isViewProcessingPage || readOnly ? false : invalid
              }
              onChange={
                onChange && !isViewPage && !isViewProcessingPage && !readOnly
                  ? onChange
                  : () => {}
              }
              // in view page make the input read only or if is readonly prop
              readOnly={isViewPage || isViewProcessingPage || readOnly}
            />

            {!isViewPage && !isViewProcessingPage ? (
              <div
                className="input-group-text py-0"
                onClick={() => setShowPassword(showPassword => !showPassword)}
              >
                {showPassword ? (
                  <i className="mdi mdi-eye-off-outline font-size-14"></i>
                ) : (
                  <i className="mdi mdi-eye-outline font-size-14"></i>
                )}
              </div>
            ) : null}
            {invalid && !isViewPage && !isViewProcessingPage && !readOnly ? (
              <FormInputError error={error} />
            ) : null}
          </InputGroup>
        ) : (
          <Fragment>
            {isLoading ? (
              <MultiSkeleton length={1} />
            ) : (
              <Input
                id={id}
                min={type === "number" && min ? min : undefined}
                max={type === "number" && max ? max : undefined}
                name={name}
                type={type}
                value={value && value}
                placeholder={readOnly ? "" : placeholder}
                className={`${type !== "textarea" && "h-100"} ${
                  inputClass ? inputClass : ""
                } ${type === "number" ? "custom-number-input" : ""} ${
                  isViewPage || isViewProcessingPage ? "border-0" : ""
                } ${readOnly ? "bg-gray-600 bg-opacity-10" : ""}`}
                {...rest}
                invalid={
                  isViewPage || isViewProcessingPage || readOnly
                    ? false
                    : invalid
                }
                onChange={
                  onChange && !isViewPage && !isViewProcessingPage && !readOnly
                    ? onChange
                    : () => {}
                }
                // in view page make the input read only or if is readonly prop
                readOnly={isViewPage || isViewProcessingPage || readOnly}
              />
            )}
          </Fragment>
        )}

        {/* Error Message */}
        {invalid && !isViewPage && !isViewProcessingPage && !readOnly ? (
          <FormInputError error={error} />
        ) : null}
      </div>
    </div>
  )
}

export default FormControll
